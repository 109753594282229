import { Component, ViewChild } from '@angular/core'
import { NavController } from '@ionic/angular'
import { NavigationEnd, Router } from '@angular/router'
import { ApiService } from './services/api/api.service'

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  @ViewChild('ionMenu', { static: false }) ionMenu
  userName: any
  date: any
  customerId: any

  constructor(
    private navController: NavController,
    private router: Router,
    public apiService: ApiService,
  ) {
    this.date = new Date().toDateString()
    console.log("app login")
    this.customerId = localStorage.getItem('customerId')
    this.userName = localStorage.getItem(this.customerId ? 'userName' : 'name')
    if (localStorage.getItem('appPages'))
      this.apiService.appPages = JSON.parse(localStorage.getItem('appPages')) 
    this.router.events.subscribe(res => {
      if (res instanceof NavigationEnd) {
        if (res.url !== '/login') {
          if (!localStorage.getItem('id') && !localStorage.getItem('customerId')) {
            this.onLogout()
          }
          if (localStorage.getItem('customerId') && !this.apiService.isOnlineUser) {
            this.onLogout()
          }
        }
      }
    })
  }

  async onLogout() {
    this.ionMenu.close()
    await localStorage.clear()
    await this.navController.navigateRoot('login')
  }
}
