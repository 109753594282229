import { Injectable } from '@angular/core'
import { LoadingController } from '@ionic/angular'

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  constructor(private loadingController: LoadingController) {
  }

  /*
  *
  * Example:
    const spinner = await this.spinnerService.spinner()
		setTimeout(() => {
			await spinner.dismiss()
		}, 2000)
  *
  * */
  async spinner() {
    const spinner = await this.loadingController.create({
      message: 'Please wait...',
      duration: 5000
    })
    await spinner.present()
    return spinner
  }
}
