import { Injectable } from '@angular/core'
import { ToastController } from '@ionic/angular'

@Injectable({
  providedIn: 'root'
})
export class ToastsService {

  constructor(private toastController: ToastController) {}

  async successToast(message?) {
    const toast = await this.toastController.create({
      message: message ?? 'successfully',
      position: 'middle',
      cssClass: 'toastsStyle',
      color: 'success',
      duration: 1500
    })
    await toast.present()
  }

  async failedToast(message?) {
    const toast = await this.toastController.create({
      message: message ?? 'failed',
      position: 'middle',
      cssClass: 'toastsStyle',
      color: 'danger',
      duration: 4000
    })
    await toast.present()
  }
}
