import { Injectable } from '@angular/core'
import { AlertController } from '@ionic/angular'

@Injectable({
  providedIn: 'root'
})
export class AlertsService {

  constructor(private alertController: AlertController) { }

  /*
  *
  *   Example:
      const result = await this.alertsService.alertSingleButton()
		  console.log(result)
  *
  * */
  async alertSingleButton(args?) {
    const alert = await this.alertController.create({
      header: args?.header ?? 'Success',
      message: args?.message ?? 'Well done',
      backdropDismiss: false,
      buttons: [{
        text: 'OK',
        handler: () => {
          alert.dismiss(true)
          return false
        }
      }]
    })
    await alert.present()
    const result = await alert.onDidDismiss()
    return result.data
  }

  async alertConfirm(args?) {
    const alert = await this.alertController.create({
      header: args?.header ?? 'Confirm!',
      message: args?.message ?? 'Sure to confirm?',
      buttons: [{
        text: 'Cancel',
        handler: () => {
          alert.dismiss(false)
          return false
        }
      }, {
        text: 'OK',
        handler: () => {
          alert.dismiss(true)
          return false
        }
      }]
    })
    await alert.present()
    const result = await alert.onDidDismiss()
    return result.data
  }
  async promptForQuantity(args?) {
    const alert = await this.alertController.create({
      header: args?.header ?? 'Enter Quantity',
      message: args?.message ?? 'Please enter a quantity:',
      inputs: [
        {
          name: 'quantity',
          type: 'number',
          placeholder: 'Enter a number',
          value: args?.value ?? '',
        },
      ],
      buttons: [
        {
          text: 'Cancel',
          handler: () => {
            alert.dismiss(false);
            return false;
          },
        },
        {
          text: 'OK',
          handler: (data) => {
            const quantity = data.quantity;
            alert.dismiss(quantity);
            return false;
          },
        },
      ],
    });
  
    await alert.present();
    const result = await alert.onDidDismiss();
    return result.data;
  }
  // todo extend to be more functional
  // https://ionicframework.com/docs/api/alert
  async showMessage(
    _header: string = 'An error occurred',
    _message: string = '',
    _buttons: {
      text: string;
      handler?: (value: any) => boolean | void | {[key: string]: any};
      role?: 'cancel' | 'destructive' | string;
      cssClass?: string | string[];
    } | any = ['Ok'],
    _inputs: any = []
  ) {
    const alert = await this.alertController.create({
      header: _header,
      message: _message,
      buttons: _buttons,
      inputs: _inputs,
      translucent: true
    })
    await alert.present()
  }
  async showMessageWithAutoClose(header: string, message: string, duration: number = 2000) {
    const alert = await this.alertController.create({
      header: header,
      message: message,
      buttons: [
        {
          text: 'OK',
          handler: () => {
            // 不做任何操作,让对话框自动关闭
          }
        }
      ]
    });
    await alert.present();
    // 等待指定的持续时间后自动关闭对话框
    setTimeout(() => {
      alert.dismiss();
    }, duration);
  }  
}
